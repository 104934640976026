import React from 'react';
import ReactDOM from 'react-dom';
import ProGalleryFullscreenMock from '../components/ProGallery/FullscreenWrapper/ProGalleryFullscreenMock';
import FullscreenAsProGallery from '../components/ProGallery/FullscreenWrapper/FullscreenAsProGallery';
import { window, experiments } from '@wix/photography-client-lib';
import '../styles/dynamic/common/FullscreenWrapperWixStyles.scss';
import { GALLERY_CONSTS } from 'pro-gallery';
import { utils } from '../utils/webUtils';

export default class CommonFullscreenWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      container: {
        width: this.isMobilePreview()
          ? this.props.getPreviewMobileEmulatorWidth()
          : window.innerWidth,
        height: window.innerHeight,
      },
      proFullscreenLoaded: false,
    };

    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.resize = this.resize.bind(this);
    this.debouncedResizeEvent = utils.debounce(this.resize, 100);
    this.lastPageScroll = -1;
    this.FullscreenElement = null;
  }

  componentDidMount() {
    this.loadFullscreenModuleIfNeeded();
  }

  componentWillMount() {
    window.addEventListener('fullscreenchange', this.onFullscreenChange);
    window.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
    window.addEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.addEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.addEventListener('resize', this.debouncedResizeEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('fullscreenchange', this.onFullscreenChange);
    window.removeEventListener(
      'webkitfullscreenchange',
      this.onFullscreenChange,
    );
    window.removeEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.removeEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.removeEventListener('resize', this.debouncedResizeEvent);
  }

  // return true if art-store and false for pro-gallery
  isStoreGallery() {
    return false;
  }

  resize() {
    this.setState({
      container: {
        width: this.isMobilePreview()
          ? this.props.getPreviewMobileEmulatorWidth()
          : window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  isMobilePreview() {
    return (
      this.props.viewMode === GALLERY_CONSTS.viewMode.PREVIEW &&
      this.props.formFactor === GALLERY_CONSTS.formFactor.MOBILE
    );
  }

  onFullscreenChange = () => {
    let container = {
      width: this.isMobilePreview()
        ? this.props.getPreviewMobileEmulatorWidth()
        : window.screen.width,
      height: window.screen.height,
    };

    if (
      !window.document.fullscreenElement &&
      !window.document.webkitIsFullScreen &&
      !window.document.mozFullScreen &&
      !window.document.msFullscreenElement
    ) {
      container = {
        width: this.isMobilePreview()
          ? this.props.getPreviewMobileEmulatorWidth()
          : window.innerWidth,
        height: window.innerHeight,
      };
    }

    this.setState({ container });
  };

  shouldUseReactPortal() {
    return (
      this.props.viewMode !== GALLERY_CONSTS.viewMode.SEO && !utils.isSSR()
    );
  }

  blockParentScroll(shouldBlock) {
    const stopScrollClass = 'pro-gallery-stop-scroll-for-fullscreen';
    const pageHtml = window.document.getElementsByTagName('html')[0];
    const classList = pageHtml && pageHtml.classList;

    try {
      if (shouldBlock && !this.parentScrollIsBlocked) {
        this.lastPageScroll = window.scrollY;
        this.parentScrollIsBlocked = true;
        classList.add(stopScrollClass);
      } else if (!shouldBlock && this.parentScrollIsBlocked) {
        this.parentScrollIsBlocked = false;
        classList.remove(stopScrollClass);
        if (this.lastPageScroll >= 0) {
          this.lastPageScroll = -1;
          window.scrollTo(0, this.lastPageScroll);
        }
      }
    } catch (e) {
      console.log('Cannot stop parent scroll', e);
    }
  }

  getFullscreenElement() {
    return this.state.proFullscreenLoaded && this.FullscreenElement
      ? this.FullscreenElement
      : null;
  }

  loadFullscreenModuleIfNeeded() {
    if (
      (experiments &&
        experiments('specs.pro-gallery.fullscreenAsProGalleryLayout') ===
          'true' &&
        !this.isStoreGallery()) ||
      utils.isSSR()
    ) {
      return;
    }
    import(
      /* webpackChunkName: "pro-fullscreen-renderer" */ '@wix/pro-fullscreen-renderer'
    ).then((module) => {
      this.FullscreenElement = module.ProFullscreen;
      this.setState({ proFullscreenLoaded: true });
    });
  }

  additionalProFullscreenProps() {
    return {};
  }

  canRender() {
    return this.props.fullscreenIdx >= 0;
  }

  getRenderElement() {
    if (!this.canRender()) {
      return null;
    }

    const ProFullscreenElement = this.getFullscreenElement();
    const props = this.props;
    const pageUrl =
      experiments && experiments('specs.pro-gallery.itemDeeplinks') === 'true'
        ? props.pageUrl
        : null;
    if (
      !this.isStoreGallery() &&
      experiments &&
      experiments('specs.pro-gallery.fullscreenAsProGalleryLayout') === 'true'
    ) {
      return (
        <FullscreenAsProGallery
          items={props.items}
          totalItemsCount={props.totalItemsCount}
          container={this.state.container}
          locale={'en'}
          galleryId={props.galleryId}
          viewMode={props.viewMode}
          noFollowForSEO={props.noFollowForSEO}
          eventsListener={props.eventsListener}
          itemsLoveData={props.itemsLoveData}
          resizeMediaUrl={props.resizeMediaUrl}
          domId={props.domId}
          allowSSR={true}
          animationDuration={props.animationDuration}
        />
      );
    } else if (ProFullscreenElement) {
      return (
        <ProFullscreenElement
          {...this.additionalProFullscreenProps()}
          items={props.items}
          initialIdx={props.fullscreenIdx}
          totalItemsCount={props.totalItemsCount}
          container={this.state.container}
          locale={props.locale}
          homeGalleryPageUrl={pageUrl}
          styles={props.styleParams}
          galleryId={props.galleryId}
          viewMode={props.viewMode}
          noFollowForSEO={props.noFollowForSEO}
          eventsListener={props.eventsListener}
          itemsLoveData={props.itemsLoveData}
          formFactor={props.formFactor}
          isPreview={props.viewMode === GALLERY_CONSTS.viewMode.PREVIEW}
          animationDuration={props.animationDuration}
        />
      );
    } else if (props.viewMode === GALLERY_CONSTS.viewMode.SEO) {
      return (
        <ProGalleryFullscreenMock
          items={
            props.viewMode === GALLERY_CONSTS.viewMode.SEO
              ? props.items
              : [props.items[this.props.fullscreenIdx]]
          }
          totalItemsCount={props.totalItemsCount}
          container={this.state.container}
          locale={'en'}
          galleryId={props.galleryId}
          viewMode={props.viewMode}
          noFollowForSEO={props.noFollowForSEO}
          eventsListener={props.eventsListener}
          itemsLoveData={props.itemsLoveData}
          resizeMediaUrl={props.resizeMediaUrl}
          domId={props.domId}
          allowSSR={true}
        />
      );
    } else if (
      props.viewMode === GALLERY_CONSTS.viewMode.SITE &&
      !this.isStoreGallery()
      // waiting for pro-fullscreen-renderer to load (inside 'loadFullscreenModuleIfNeeded'))
    ) {
      const bgColorExpand =
        (props.styleParams.bgColorExpand &&
          props.styleParams.bgColorExpand.value) ||
        '';
      return (
        <div
          className="pro-fullscreen-wrapper-loading"
          style={{
            backgroundColor: bgColorExpand,
          }}
        />
      );
    } else {
      return null;
    }
  }

  getStyleForWrapper() {
    return {
      opacity: this.props.fullscreenAnimating ? 0 : 1,
      transition: 'opacity .8s ease',
      overflow: 'hidden',
    };
  }

  addMobilePreviewStylesIfNeeded() {
    if (this.isMobilePreview()) {
      return {
        left: this.props.getPreviewMobileEmulatorLeft() + 'px',
        width: this.state.container.width + 'px',
      };
    } else {
      return {};
    }
  }

  render() {
    const renderElement = this.getRenderElement();
    if (!renderElement) {
      return null;
    }
    const fullscreen = (
      <div
        className="pro-fullscreen-wrapper"
        style={{
          ...this.getStyleForWrapper(),
          ...this.addMobilePreviewStylesIfNeeded(),
        }}
      >
        {renderElement}
      </div>
    );
    return this.shouldUseReactPortal()
      ? ReactDOM.createPortal(fullscreen, document.body)
      : fullscreen;
  }
}
